<template>
  <Navbar />
  <div class="text-center" v-if="loading === true">
    <img :src="require('@/assets/img/loader.gif')" alt="" />
    <p>Encours...</p>
  </div>
  <div class="container" v-if="failed === true">
    <p class="alert alert-danger">
      Le lien a expiré veillez redemander un autre
    </p>
    <form action=""></form>
  </div>
</template>
<script>
import Navbar from "../../components/layouts/Navbar.vue";
export default {
  components: { Navbar },
  data() {
    return {
      failed: null,
      loading: false,
    };
  },
  methods: {
    async checkEmail() {
      this.loading = true;
      this.$http
        .get(`/auth/email-verify?token=${this.$route.params.token}`)
        .then((res) => {
          if (res.status == 200) {
            this.$router.push({ name: "Home" });
            this.toast.success(this.$t("FLASH.SUCCESS"));
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.failed = true;
        });
    },
  },
  beforeMount() {
    this.$nextTick(() => this.checkEmail());
  },
};
</script>
